import React from "react";
import { Outlet } from "react-router-dom";
import SideMenu from "../Components/SideMenu";

export default class Dashboard extends React.Component{

  componentDidMount(){
   
  }

  constructor(props){
  super(props)

  }
   render (){
     return <section className="latestFiles">
     <div className="mobHead">
       <div className="container">
         <div className="row">
           <div className="col-6">
             <i className="fa fa-bars" />
           </div>
           <div className="col-6">
             <img src="https://i1.wp.com/www.nabd.dk/wp-content/uploads/2020/04/avatar-png-1.png?ssl=1" alt="img" />
           </div>
         </div>
       </div>
     </div>

     <div className="outing" />
     <div className="container-fluid">
        <SideMenu/>
       <div className="innerContent">
          <Outlet/>
       </div>{/*end innerContent*/}
     </div>{/*end container*/}
   </section>
   }       
}