import React, { useState } from 'react';
import './ImageUploadForm.css'; // Import the CSS file for styles

const ImageUploadForm = () => {
  const [file, setFile] = useState(null);
  const [response, setResponse] = useState(null);

  const uploadImage = async () => {
    if (!file) {
      alert("Please select a file.");
      return;
    }

    const formData = new FormData();
    formData.append("fileToUpload", file);

    try {
      const response = await fetch(window.baseUrl + "file/upload", {
        method: "POST",
        headers: {
          "Authorization": "Bearer " + localStorage.getItem("token"),
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error("File upload failed: " + response.statusText);
      }
      alert("تم تحديث البيانات بنجاح");


    } catch (error) {
      console.error(error);
      alert("Error uploading file.");
    }
  };

  return (
    <div className="upload-form-container">
      <h1 className="form-title">ملف السيارات</h1>
      <form className="upload-form" onSubmit={(e) => { e.preventDefault(); uploadImage(); }}>
        <div className="form-group">
          <label className="form-label">
            اختر:
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setFile(e.target.files[0])}
              required
              className="file-input"
            />
          </label>
        </div>
        <button type="submit" className="submit-button">تأكيد</button>
      </form>
      {response && <div className="response-message">Upload successful: {response}</div>}
    </div>
  );
};

export default ImageUploadForm;