import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

var city = "الجبيل"
export default function AddDriver(){
    let navigate = useNavigate();
    const cites = [
      "جدة",
      "الرياض",
      "المنطقة",
      "مكة المكرمة",
      "الجبيل",
      "تبوك",
      "حائل",
      "الدمام",
    ];

    const postForm = async (event) => {
        event.preventDefault()
        const formdata = new FormData(event.target)
          try {
          const response = await fetch(window.baseUrl+"admin/driver-signup", {
            method: "POST",
            headers: {
              "Authorization":"Bearer "+localStorage.getItem("token")
              
            },
            body:formdata
          });
          const json = await response.json();
          console.log(json);
          navigate("/admin/settingss");
        } catch (error) {
          console.error(error);
        }
      };


    return <section className="addClient">
    <div className="clientHead">
      <div className="row">
        <div className="col-md-6 col-6">
          <h4>اضافة <br /> سائق جديد</h4>
        </div>
        {/* <div className="col-md-6 col-6">
          <i className="fa fa-user svgUser" />
          <i className="addIcon fa fa-plus " />
        </div> */}
      </div>{/*end row*/}
    </div>{/*end clientHead*/}
    <div className="clientForm">
    <form  onSubmit={postForm}>
        <div className="row">
        <div className="col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">@</span>
              </div>
              <input name="fullName" type="text" className="form-control" placeholder=" الاسم بالكامل" />
            </div>
          </div>
          {/* <div className="col-md-6">
            <div className="input-group">
              <input type="text" className="form-control" placeholder="الاسم الاخير" />
            </div>
          </div> */}
          <div className="col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">@</span>
              </div>
              <input name="email" type="text" className="form-control" placeholder="البريد الالكتروني" />
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1"><i className="fa fa-phone" /></span>
              </div>
              <input name="userName" type="text" maxLength={9} className="form-control" placeholder="الهاتف" />
            </div>
          </div>
          <div className="col-md-12">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">@</span>
              </div>
              <input name="ibanNumber" type="text" className="form-control" placeholder=" رقم الأيبان" />
            </div>
          </div>

          <div className="col-md-12">

          <div className="form-group">
                <label asp-for="City">اختر المنطقة</label>
                <select
                  asp-for="City"
                  onChange={(e)=>city=e.target.value}
                  name="subjectId"
                  className="form-control select2"
                  style={{ width: "100%" }}
                >
                  {cites.map((row)=><option value={row}>{row}</option>)}
                 
                </select>
              </div>
         </div>


        </div>
        <div className="btnGroub">
        <div className="row">
          <div className="col-md-12">
          <input className="systemBtn bg-[#a72727]  font-bold" value={"تسجيل"}  type="submit"
/>
          </div>
        </div>
      </div>
      </form>
     
    </div>
  </section>
  
  
}