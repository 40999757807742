import { border } from "@mui/system";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
export default function OrderDetail() {
  const [order,setOrder] = useState(null)
  const location = useLocation();
  const imgStyle = { height: "100px", borderRadius: "100px", margin: "auto" };

  const row = location.state.row;

  const orderStatus = [
    "تلقي العروض",
    "معتمد",
    "متوجه للاستلام",
    "جاري التوصيل",
    "تم تسليم الطلب",
    "طلب ملغي",
    "الكل",

  ];

  const getOrder = async () => {
    try {
      var form = new FormData()
      form.append("orderId",row?.order?.id)
      form.append("driverId",row?.order?.approvedDriverId)
      const response = await fetch(
        window.baseUrl+"order/detail",
        {
          method: "post",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body:form
        }
      );
      const json = await response.json();
      console.log(json);
      setOrder(json)
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
   getOrder()
  },[]);
  
  const showMap = (lat, lng) => {
    window.open("https://maps.google.com?q=" + lat + "," + lng);
  };
  return (
    order&&  <div className="container">
    <div className="row order-detail  ">
      <div className="col-sm-5 m-2 p-3 border">
        <div className="item-container h-[180px] col-12">

          <div className="item-title mt-2">
            رقم الطلب{" "}
            <span className="item-content mx-3">{" # " + row?.order?.id}</span>
          </div>
          <div className="item-title mt-1">
            تاريخ الطلب
            <span className="item-content mx-3">
              {" " + row?.order?.createdAt.split("T")[0]}
            </span>
          </div>
          <div className="item-title mt-1">
            حالة الطلب
            <span className="item-content mx-3">
              {" " + orderStatus[row?.order?.status]}
            </span>
          </div>
         

         { (row?.order.deliveryCost>0)&& (<div className="item-title mt-1">
            إجمالي السعر
            <span className="item-content mx-3 ">
              {row?.order?.deliveryCost + " ريال "}
            </span>
          </div>)}


          <h5 className="my-3 text-success">

            السعر شامل قيمة الضريبة المضافة وقيمة خدمة للتطبيق (35%)
          </h5>
          {/* <div className="item-title mt-1">
          قيمة التوصيل{" "}
          <span className="item-content mx-3">
            {row?.order?.tax + " ريال "}
          </span>
        </div>

        <div className="item-title mt-1">
          قيمة الضريبة{" "}
          <span className="item-content mx-3">
            {row?.order?.tax + " ريال "}
          </span>
        </div> */}

        
        </div>
      </div>
      <div className="col-sm-5 m-2 p-3 border">
        <div className="item-container h-[120px] col-12">
          <div className="flex justify-between">
            <div>
              <div className="item-title mt-1">
                اسم العميل
                <span className="item-content mx-3">{row?.user?.fullName}</span>
              </div>
              <div className="item-title mt-1">
                رقم الهاتف
                <span className="item-content mx-3">{row?.user?.userName}</span>
              </div>

              

              <div className="item-title mt-1">
                البريد الإلكتروني
                <span className="item-content mx-3">{row?.user?.email}</span>
              </div>
            </div>

            <div className="w-[100px]">
            <img
                        src={
                          window.baseUrl+"uploads/" + row?.user?.imageUrl
                        }
                        onError={(e) => (
                          (e.target.onerror = null),
                          (e.target.src = "../img/account.jpeg")
                        )}
                        alt="img"
                      />
            </div>
          </div>

          <div
            onClick={(e) => showMap(row?.order?.userLat, row?.order?.userLng)}
            className="btn btn-success my-3 mx-2"
          >
            موقع العميل
          </div>
        </div>
      </div>

    
      {order&& (
        <div className="col-sm-5 m-2 p-3 border p-3 ">
          <div className="col-content col-12 h-[268px]">
            {order?.driverUser &&<>
                <div className="item-title mt-1">
              اسم المندوب
              <span className="item-content mx-3">
                {order?.driverUser?.fullName}
              </span>
            </div>
            <div className="item-title mt-1">
              مدة الضمان
              <span className="item-content mx-3">
                {order?.order?.warranty+" يوم "}
              </span>
            </div>
            <div className="item-title mt-1">
             وقت التوصيل
              <span className="item-content mx-3">
                {order?.order?.time}
              </span>
            </div>
            </>}

            <div className="item-title mt-1">
             تفاصيل الطلب
              <span className="item-content flex  mx-3">
                {order?.order?.detail}
              </span>
             
            </div>

            <div className="item-title mt-1">
             الصور
              <span className="item-content flex mx-3 py-3">
              {
          order.order.images&&order?.order.images.split(",").map((e,index)=>e!=null&&e.length>4&&<img className="h-[100px]" src={window.baseUrl+"uploads/"+e} alt="img" />)
           }              </span>
            </div>
            

          </div>
        </div>
      )}
        {order&&order?.driverUser && (
        <div className="col-sm-5 m-2 p-3 border p-3 ">
            <div>الدفع</div>
          <div className="col-content col-12 h-[268px]">
          <div className="item-title mt-1">
             رقم العملية
              <span className="item-content mx-3">
                {order?.order?.payment.split(",")[0]}
              </span>
            </div>
            <div className="item-title mt-1">
            المبلغ
              <span className="item-content mx-3">
                {order?.order?.payment.split(",")[1]}
              </span>
            </div>
            <div className="item-title mt-1">
            نوع البطاقة
              <span className="item-content mx-3">
                {order?.order?.payment.split(",")[2]}
              </span>
            </div>

            <div className="item-title mt-1">
             البطاقة
              <span className="item-content mx-3">
                {order?.order?.payment.split(",")[3]}
              </span>
            </div>
          
   
          </div>
        </div>
      )}


    </div>
  </div>
  );
}


