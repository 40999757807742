import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Components/Spinner";

export default function Home(){

var [data, setData] = useState(null);
const orderStatus = [
  "تلقي العروض",
  "معتمد",
  "متوجه للاستلام",
  "جاري التوصيل",
  "تم تسليم الطلب",
  "طلب ملغي",
];
const navigate = useNavigate()
const getData = async () => {
  try {
    const response = await fetch(window.baseUrl+"dashboard-home", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization":"Bearer "+localStorage.getItem("token")
      },
    });
    const json = await response.json();
    console.log(json);
    setData(json);
    return json;
  } catch (error) {
    console.error(error);
  }
};

if (data == null) getData();

    return (data == null ? (
      <Loading/>
    ) :  <>
      <div className="headCon">
        <div className="row">
          <div className="col-md-4 col-12">
            <div className="titlePage">
              <h2>ملخص الاداء</h2>
              <p>هنا يقبع ملخص الأداء لكل الإدارات الخاصة بالنظام اضغط علي أيها للمزيد من التفاصيل</p>
            </div>
          </div>
          <div className="col-md-2 col-6">
            <div className="statBox">
              <h5>عدد العملاء</h5>
              <small>{data.users}</small>
              <h5>عدد السائقين</h5>
              <small>{data.drivers}</small>
              <i className="fa fa-user" />
            </div>{/*end statBox*/}
          </div>
          <div className="col-md-2 col-6">
            <div className="statBox">
              <h5>عدد السيارات</h5>
              <small>{data.cars}</small>
              <h5>الشركات</h5>
              <small>{data.companies}</small>
            </div>{/*end statBox*/}
          </div>
          <div className="col-md-2 col-6">
            <div className="statBox">
              <h5> الطلبات المكتملة</h5>
              <small>{data.completeOrders}</small>
              <h5>الطلبات قيد العمل</h5>
              <small>{data.pendingOrders}</small>
            </div>{/*end statBox*/}
          </div>
          <div className="col-md-2 col-6">
            <div className="statBox">
              <h5>اجمالي الدخل</h5>
              <small>{data.totalIncome}</small>
              <h5>الأرباح</h5>
              <small>{data.earnings}</small>
              <i className="fa fa-money-bill" />
            </div>{/*end statBox*/}
          </div>
        </div>
      </div>{/*end headCon*/}
      <div className="row">
      
        <div className="col-md-12">
          <h4 className="titleSection">احدث السجلات</h4>
          <div className="table-responsive">
            <table className="table filesTable table-striped" id="example"> 
            <thead>
            <tr>
              <th scope="col"><input type="checkbox" /></th>
              <th scope="col"><i className="fa fa-star" /></th>
              <th scope="col">م</th>
              <th scope="col">تاريخ</th>
              <th scope="col">اسم العميل</th>
              <th scope="col">البريد</th>
              <th scope="col">الهاتف</th>
              <th scope="col">المنطقة</th>
              <th scope="col">صورة</th>
              <th scope="col">الحالة</th>
            </tr>
          </thead>
              <tbody>
              {data&&data.recentOrders.map((e,index)=>      
                    <tr>
              <td><input type="checkbox" /></td>
              <td><i className="fa fa-star" /></td>
              <td>{index+1}</td>
              <td>{e.order.createdAt.split("T")[0]}</td>
              <td>{e.user.fullName}</td>
              <td>{e.user.email}</td>
              <td>{e.user.userName}</td>
              <td>{e.order.city}</td>

              <td>{e.order.images&&<img  src={window.baseUrl+"uploads/"+e.order.images.split(",")[0]} alt="img" />}</td>
              <td><h5 className="w-[150px] font-bold text-[18px] text-[green] ">{orderStatus[e.order.status]}</h5></td>
            </tr>)}

          </tbody>
            </table>
          </div>
        </div>
      </div>    </>)
    
    
}