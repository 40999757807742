import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import ImageUploadForm from "./CarsData";

var city = "الجبيل"
export default function AppSettings(){

    var [data, setData] = useState(null);

    let navigate = useNavigate();
    const cites = [
      "جدة",
      "الرياض",
      "المنطقة",
      "مكة المكرمة",
      "الجبيل",
      "تبوك",
      "حائل",
      "الدمام",
    ];



 useEffect(() => {
  if (data == null) getData();}
  , []);


const getData = async () => {
  try {
    const response = await fetch(window.baseUrl+"get-app-settings", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Authorization":"Bearer "+localStorage.getItem("token")
      },
    });
    const json = await response.json();
    console.log(json);
    setData(json);
    return json;
  } catch (error) {
    console.error(error);
  }
};





    const postForm = async (event) => {
        event.preventDefault()
        const formdata = new FormData(event.target)
        const jsonRequest = {}
     
        console.log("post");
        console.log(jsonRequest);
        try {
          const response = await fetch(window.baseUrl+"dashboard-settings", {
            method: "POST",
            headers: {
             
              "Authorization":"Bearer "+localStorage.getItem("token")
            },
            body:formdata
          });
          
        } catch (error) {
          console.error(error);
        }
      };
    
      const findElementByKey=( targetKey) =>{
        return data.find(item => item.name === targetKey).value;
      }

    return <section className="addClient">
    <div className="clientHead">
      <div className="row">
        <div className="col-md-12 col-6">
          <h4>  الإعدادات</h4>
        </div>
        {/* <div className="col-md-12 col-6">
          <i className="fa fa-user svgUser" />
          <i className="addIcon fa fa-plus " />
        </div> */}
      </div>{/*end row*/}
    </div>{/*end clientHead*/}
    <div className="clientForm">
       {data&&<form  onSubmit={postForm}>
        <div className="row">
        <div className="col-md-12">
           
          </div>
          <div className="col-md-12">
            <div className="input-group">
            <label className="col-12">رقم االتواصل </label>
              <input name="WhatsApp" defaultValue={findElementByKey("whatsapp")} type="text" className="form-control" placeholder="رقم الواتساب" />
            </div>
          </div> 


          <div className="col-md-12">
            
            <div className="input-group">
            <label className="col-12">سعر الحجم الصغير</label>

              <input defaultValue={findElementByKey("naqel_small")} name="NaqelSmall" type="text" className="form-control" placeholder="الحجم الصغير" />
            </div>
          </div> 

          <div className="col-md-12">
            <div className="input-group">
            <label className="col-12">سعر الحجم الكبير</label>
              <input defaultValue={findElementByKey("naqel_larg")}  name="NaqelLarg" type="text" className="form-control" placeholder="الحجم الكبير" />
            </div>
          </div> 

          <div className="col-md-12">
            <div className="input-group">
            <label className="col-12">رسالة الحجم الصغير</label>
              <input defaultValue={findElementByKey("naqel_small_message")} name="NaqelSmallMessage" type="text" className="form-control" placeholder="رسالة الحجم الصغير" />
            </div>
          </div> 

          <div className="col-md-12">
            <div className="input-group">
            <label className="col-12">رسالة الحجم الكبير</label>

              <input defaultValue={findElementByKey("naqel_larg_message")} name="NaqelLargMessage" type="text" className="form-control" placeholder="رسالةالحجم الكبير" />
            </div>
          </div> 

         

        </div>
        <div className="btnGroub">
        <div className="row">
          <div className="col-md-12">
          <input   className="systemBtn bg-[#a72727] text-[white] font-bold" value={"حفظ"}  type="submit"
/>
          </div>
        </div>
      </div>
      </form>}

      <ImageUploadForm />
     
    </div>
  </section>
  
  
}