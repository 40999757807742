import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

import './App.css';
import Dashboard from './Pages/Dashboard';
import Home from './Pages/Home';
import Orders from './Pages/Orders';
import Accouns from './Pages/Accounts';
import Drivers from './Pages/Drivers';
import AddDriver from './Pages/AddDriver';
import Cars from './Pages/Cars';
import Transactions from './Pages/Transactions';
import Login from './Pages/Login';
import OrderDetail from './Pages/OrderDetail';
import Cities from './Pages/Cities';
import AddCity from './Pages/AddCity';
import AppSettings from './Pages/AppSettings';

function App() {
   //window.baseUrl = "https://localhost:44308/"
   window.baseUrl = "https://api.tashleehxpress.com/"
  return (
    <Router forceRefresh={true}>
    <Routes >
      <Route path="/" element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="admin"  element={<Dashboard />}>
      <Route path="" element={<Home />} />
      <Route path="home" element={<Home />} />
      <Route path="orders" element={<Orders/>} />
      <Route path="accounts" element={<Accouns />} />
      <Route path="cars" element={<Cars />} />
      <Route path="cities" element={<Cities />} />
      <Route path="add-city" element={<AddCity />} />
      <Route path="drivers" element={<Drivers />} />
      <Route path="drivers/add" element={<AddDriver />} />
      <Route path="transactions" element={<Transactions />} />
      <Route path="order/detail" element={<OrderDetail />} />
      <Route path="settings" element={<AppSettings />} />
      </Route>
    </Routes>
  </Router>
  
  );
}

export default App;
