import { useEffect, useState } from "react";

import Loading from "../Components/Spinner";


export default function Cities(){
  var [data, setData] = useState(null);

 const getData = async () => {
    try {
      const response = await fetch(
        window.baseUrl+"cities",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const json = await response.json();
      console.log(json);
      setData(json);
      return json;

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getData()
   },[]);

  return  <div className="row">
    <div className="col-md-12">
    <div className="row">
        <div className="col-2">
        <h4 className="titleSection">جميع المدن</h4>
        </div>
    
      </div>
               <div className="table-responsive">
        <table className="table filesTable table-striped" id="example">
          <thead>
            <tr>
            
              <th scope="col">
                <i className="fa fa-star" />
              </th>
              <th scope="col">م</th>
              <th scope="col">اسم المدينة</th>
              <th scope="col">كود لناقل</th>

            </tr>
          </thead>
          <tbody>
            { (
          data&&  data.slice(0, 200).map((e, index) => (
                <tr>
                 
                  <td>
                    <i className="fa fa-star" />
                  </td>
                  <td>{index + 1}</td>
                  <td>{e.name_ar ?? ""}</td>
                  <td>{e.code ?? ""}</td>
              
                </tr>
              ))
            )}
          </tbody>
        </table>
    
      </div>

    </div>
  </div>
  
}

