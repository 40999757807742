import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

var city = "الجبيل"
export default function AddCity(){
    let navigate = useNavigate();

    const postForm = async (event) => {
        event.preventDefault()
        const formdata = new FormData(event.target)
       
        try {
          const response = await fetch(window.baseUrl+"city/add", {
            method: "POST",
            headers: {
        
              "Authorization":"Bearer "+localStorage.getItem("token")
            },
            body:formdata
          });
          const json = await response.json();
          console.log(json);
          navigate("/admin/cities");
        } catch (error) {
          console.error(error);
        }
      };


    return <section className="addClient">
    <div className="clientHead">
      <div className="row">
        <div className="col-md-6 col-6">
          <h4>اضافة <br />  مدينة</h4>
        </div>
    
      </div>{/*end row*/}
    </div>{/*end clientHead*/}
    <div className="clientForm">
    <form  onSubmit={postForm}>
        <div className="row">
        <div className="col-md-12">
            <div className="input-group">
             
              <input name="name_ar" type="text" className="form-control" placeholder=" اسم المدينة" />
            </div>
          </div>
      
          <div className="col-md-12">
            <div className="input-group">
        
              <input name="code" type="text" className="form-control" placeholder="كود ناقل" />
            </div>
          </div>

        

        </div>
        <div className="btnGroub">
        <div className="row">
          <div className="col-md-12">
          <input className="systemBtn bg-[#a72727]  font-bold" value={"تأكيد"}  type="submit"
/>
          </div>
        </div>
      </div>
      </form>
     
    </div>
  </section>
  
  
}