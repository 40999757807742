import { Link, useLocation, useNavigate } from "react-router-dom";

export default function SideMenu() {
  let navigate = useNavigate();
  let location = useLocation();
  function isActive(route) {
    console.log(location.pathname);
    return location.pathname == route ? "dropdown-btn active" : "dropdown-btn";
  }

  return (
    <div className="sideMenu">
      <div className="userDetails ">
        <img
          className="userImg m-auto"
          src="https://i1.wp.com/www.nabd.dk/wp-content/uploads/2020/04/avatar-png-1.png?ssl=1"
        />
        <h5 className="userName mt-[20px]"> {localStorage.getItem("name")}</h5>
        <small className="userTitle">ادمن</small>
      </div>
      <div className="sidenav">
        <button onClick={(e) => navigate("/admin/home")} className={isActive("/admin/home")}>

          الرئيسية <i className="fa-solid fa-house icon" />
          <i className="fa fa-caret-down" />

        </button>
        <button onClick={(e) => navigate("/admin/accounts")} className={isActive("/admin/accounts")}>

          الحسابات <i className="fa-solid fa-users icon" />
          <i className="fa fa-caret-down" />

        </button>

        <div onClick={(e) => navigate("/admin/orders")} className={isActive("/admin/orders")}>

          الطلبات <i className="fa-solid fa-clipboard-list icon" />
          <i className="fa fa-caret-down" />

        </div>

        <button onClick={(e) => navigate("/admin/cars")} className={isActive("/admin/cars")}>

          السيارات <i class="fa-solid fa-car icon" ></i>
          <i className="fa fa-caret-down" />

        </button>

        <button onClick={(e) => navigate("/admin/cities")} className={isActive("/admin/cities")}>

          المدن <i class="fa-solid fa-car icon"></i>

          <i className="fa fa-caret-down" />

        </button>

        <button onClick={(e) => navigate("/admin/add-city")} className={isActive("/admin/add-city")}>

          إضافة مدينة <i class="fa-solid fa-car icon"></i>

          <i className="fa fa-caret-down" />

        </button>


        <button onClick={(e)=>navigate("/admin/settings")} className={isActive("/admin/settings")}>

            الإعدادات <i className="fa-solid fa-credit-card icon" />
            <i className="fa fa-caret-down" />

          </button>

        <button onClick={(e) => navigate("/admin/drivers")} className={isActive("/admin/drivers")}>
          السائقين <i className="fa-solid fa-user-group icon" />
          <i className="fa fa-caret-down" />
        </button>

        <button onClick={(e) => navigate("/admin/drivers/add")} className={isActive("/admin/drivers/add")}>
          إضافة سائق جديد <i className="fa-solid fa-user-group icon" />
          <i className="fa fa-caret-down" />
        </button>

        <button onClick={(e) => navigate("/")} className="dropdown-btn">
          تسجيل الخروج <i className="fa-solid fa-right-from-bracket icon" />
          <i className="fa fa-caret-down" />

        </button>
      </div>
    </div>
  );
}
